



































































































import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { PagedResults, ReportedQuestionInfo, TableQuery, TableState, ReportedLessonInfo, Api, ReportedListInfo, ReportedListWordInfo, ReportedGrammarQuestionInfo, getGrammarGameByIdent, GrammarGameIdent } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'

@Component
export default class ReportedQuestions extends Mixins(ModalMixin, ComponentHelper) {
  private isLoading: boolean = false

  private reportedContent: PagedResults<ReportedQuestionInfo> | PagedResults<ReportedLessonInfo> | PagedResults<ReportedListInfo> | PagedResults<ReportedListWordInfo> | PagedResults<ReportedGrammarQuestionInfo> = { items: [], total: 0 }
  private currentTab = 0

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'created',
    dir: 'asc',
    term: ''
  }

  public mounted () {
    if (this.$route.query.tab && typeof this.$route.query.tab === 'string') {
      const tab = parseInt(this.$route.query.tab)
      this.getData(tab)
    } else {
      this.getData(0)
    }
  }

  get currentTitle () {
    switch (this.currentTab) {
      case 0:
        return 'Reported Quizzes'
      case 1:
        return 'Reported Questions'
      case 2:
        return 'Reported Lessons'
      case 3:
        return 'Reported Lesson Episodes'
      case 4:
        return 'Reported Spelling Lists'
      case 5:
        return 'Reported Spelling Words'
      case 6:
        return 'Reported Grammar Questions'
      default:
        return 'Reported Content'
    }
  }

  getReportedGrammarQuestionTitle (report: ReportedGrammarQuestionInfo) {
    const gameIdent = report.question.game_ident
    return `${gameIdent} question. ID: ${report.question.id}`
  }

  public getTitle (report: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo | ReportedGrammarQuestionInfo) {
    switch (this.currentTab) {
      case 0:
        return (report as ReportedQuestionInfo).question_set_title
      case 1:
      // eslint-disable-next-line no-case-declarations
        const _report = report as ReportedQuestionInfo
        return _report.question
          ? JSON.parse(_report.question).value
          : ''
      case 2:
        return (report as ReportedLessonInfo).lesson_title
      case 3:
        return (report as ReportedLessonInfo).lesson_episode_title
      case 4:
        return (report as ReportedListInfo).list_title
      case 5:
        return (report as ReportedListWordInfo).text
      case 6:
        return this.getReportedGrammarQuestionTitle(report as ReportedGrammarQuestionInfo)
      default:
        return 'Reported Content'
    }
  }

  public getIdentField () {
    switch (this.currentTab) {
      case 0:
        return 'question_set_ident'
      case 1:
        return 'question_set_ident'
      case 2:
        return 'lesson_ident'
      case 3:
        return 'lesson_episode_ident'
      case 4:
        return 'list_ident'
      case 5:
        return 'list_word_ident'
      default:
        return 'Reported Content'
    }
  }

  public async getData (tab: number = this.currentTab) {
    const method = this.getApiMethod(tab)
    const query: TableQuery = {
      skip: (this.tableState.page - 1) * this.tableState.perPage,
      take: this.tableState.perPage,
      sort: this.tableState.sort,
      dir: this.tableState.dir,
      term: this.tableState.term
    }
    try {
      this.isLoading = true

      const res = await Api[method](query)
      if (res) {
        this.reportedContent = res
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: error.message })
      }
    } finally {
      this.isLoading = false
    }
  }

  public getGrammarGameName (ident: GrammarGameIdent) {
    const game = getGrammarGameByIdent(ident)
    if (game) {
      return game.title
    }
    return 'grammar game'
  }

  public getApiMethod (tab) {
    switch (tab) {
      case 0:
        return 'getReportedQuestionSets'
      case 1:
        return 'getReportedQuestions'
      case 2:
        return 'getReportedLessons'
      case 3:
        return 'getReportedLessonEpisodes'
      case 4:
        return 'getReportedLists'
      case 5:
        return 'getReportedListWords'
      case 6:
        return 'getReportedGrammarQuestions'
      default:
        return 'getReportedQuestionSets'
    }
  }

  public pageChanged (page: number) {
    this.tableState.page = page
    this.getData()
  }

  public tabChanged () {
    this.tableState.page = 1
    this.getData()
  }

  getLinkForGrammarQuestion (report: ReportedGrammarQuestionInfo) {
    return `/grammar?g=${report.question.game_ident}&n=${report.question.lesson_id}&q=${report.question.id}`
  }

  getLinkForRow (row: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo | ReportedGrammarQuestionInfo) {
    switch (this.currentTab) {
      case 0:
        return this.config.targetInfo.quiz + 'browse/' + (row as ReportedQuestionInfo).set_ident
      case 1:
        return this.config.targetInfo.quiz + 'browse/' + (row as ReportedQuestionInfo).set_ident + '#' + (row as ReportedQuestionInfo).question_id
      case 2:
        return this.config.targetInfo.quiz + 'lessons/' + (row as ReportedLessonInfo).lesson_ident
      case 3:
        return this.config.targetInfo.quiz + 'lessons/' + (row as ReportedLessonInfo).lesson_ident + '#' + (row as ReportedLessonInfo).lesson_episode_id
      case 4:
        return this.config.targetInfo.auth + 'lists/' + (row as ReportedListInfo).list_ident
      case 5:
        return this.config.targetInfo.auth + 'lists/' + (row as ReportedListWordInfo).list_ident
      default:
        return Api.getReportedQuestionSets
    }
  }

  isReportedQuestionInfo (report: ReportedQuestionInfo | ReportedLessonInfo): report is ReportedQuestionInfo {
    return 'question_id' in report || 'question_set_id' in report
  }

  isReportedLessonInfo (report: ReportedQuestionInfo | ReportedLessonInfo): report is ReportedLessonInfo {
    return 'lesson_id' in report || 'lesson_episode_id' in report
  }

  async closeReport (report: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo) {
    let remove_flag = true
    if (report.description.includes('Flagged')) {
      remove_flag = await this.confirm({
        title: 'Remove content flag?',
        message: 'This content has been flagged by AI for inappropriate content. Deleting this report will remove the flag from the content so it can be published, do you want to continue?'
      })
      if (remove_flag) {
        if (this.isReportedLessonInfo(report as ReportedLessonInfo)) {
          this.closeAndRemoveFlag(report as ReportedLessonInfo)
        } else if (this.isReportedQuestionInfo(report as ReportedQuestionInfo)) {
          this.closeAndRemoveFlag(report as ReportedQuestionInfo)
        }
      }
    } else {
      if (this.currentTab === 0 || this.currentTab === 1) {
        this.closeReportedQuestion(report.id)
      } else if (this.currentTab === 2 || this.currentTab === 3) {
        this.closeReportedLesson(report.id)
      } else {
        this.closeReportedList(report.id)
      }
    }
  }

  async closeAndRemoveFlag (report: ReportedQuestionInfo | ReportedLessonInfo) {
    try {
      await Api.removeFlag(report)
      this.$buefy.toast.open({ message: 'Succesfully removed flag and closed report', type: 'is-success', position: 'is-bottom' })
      await this.getData()
    } catch (err) {
      this.$buefy.toast.open({ message: 'Error removing flag', type: 'is-danger', position: 'is-bottom' })
    }
  }

  deleteContent (row: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo) {
    if (this.currentTab === 0 || this.currentTab === 1) {
      throw new Error('Action not supported')
    } else if (this.currentTab === 2 || this.currentTab === 3) {
      throw new Error('Action not supported')
    } else if (this.currentTab === 4) {
      this.confirmDeleteList(row as ReportedListInfo)
    } else if (this.currentTab === 5) {
      this.confirmDeleteListWord(row as ReportedListWordInfo)
    }
  }

  confirmDeleteList (row: ReportedListInfo) {
    this.$buefy.dialog.confirm({
      title: 'Delete List',
      message: `Are you sure you wish to delete list "${row.list_title}"? This action cannot be undone.`,
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        await Api.deleteList(row.list_ident)
      }
    })
  }

  confirmDeleteListWord (row: ReportedListWordInfo) {
    this.$buefy.dialog.confirm({
      title: 'Remove Word',
      message: `Are you sure you wish to remove word "${row.dictionary.word}" from list "${row.list_title}"? This action cannot be undone.`,
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        await Api.deleteWordFromList(row.list_ident, row.list_word_ident)
      }
    })
  }

  async closeReportedGrammarQuestion (reportId: number) {
    try {
      this.isLoading = true
      await Api.closeGrammarQuestionReport(reportId)
      this.getData()
      this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Report closed Successfully!' })
    } catch (err) {
      this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Could not close the report!' })
    } finally {
      this.isLoading = false
    }
  }

  async closeReportedQuestion (reportId: number) {
    try {
      this.isLoading = true
      await Api.closeQuestionReport(reportId)
      this.getData()
      this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Report closed Successfully!' })
    } catch (err) {
      this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Could not close the report!' })
    } finally {
      this.isLoading = false
    }
  }

  async closeReportedLesson (reportId: number) {
    try {
      this.isLoading = true
      await Api.closeLessonReport(reportId)
      this.getData()
      this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Report closed Successfully!' })
    } catch (err) {
      this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Could not close the report!' })
    } finally {
      this.isLoading = false
    }
  }

  async closeReportedList (reportId: number) {
    try {
      this.isLoading = true
      await Api.closeListReport(reportId)
      this.getData()
      this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Report closed Successfully!' })
    } catch (err) {
      this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Could not close the report!' })
    } finally {
      this.isLoading = false
    }
  }
}
